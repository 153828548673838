<template>
  <div class="panel" :class="canEditProject ? '' : 'disabled'">
    <div class="main">
      <section class="p-5">
        <h4>Project Resources</h4>
        <h6>Surrounds Models</h6>
        <p class="mb-2 ml-4">
          <i
            >Surrounds models can be automatically generated for your project
            using data collected from
            <a href="https://openstreetmap.org/copyright" target="blank"
              >OpenStreetMap</a
            >. OpenStreetMap data is user submitted and may contain missing or
            incorrect data so we strongly suggest that all generated surrounds
            models are reviewed for accuracy. Pay particular attention to the
            heights of the buildings as outside of large urban centers this data
            is often missing.
            <strong
              >When a building's height is not available it will default to a
              single floor. Please review all building heights and adjust
              accordingly in your CAD software.</strong
            ></i
          >
        </p>
        <div v-if="surroundsHasErrorsOSM" class="text-danger mb-2 ml-4 mt-2">
          There was an error generating OSM surrounds. Please try again.
        </div>
        <div v-if="surroundsHasNoBuildings" class="text-danger mb-2 ml-4 mt-2">
          There are no buildings in the area designated for the project. This will not impact the simulation.
        </div>
        <b-button
          class="ml-4"
          variant="primary"
          @click="generateSurroundsOSM"
          :disabled="generatingSurrounds"
          v-if="(projectSurrounds !== null && !projectHasSurroundsOSM) || surroundsHasErrorsOSM || surroundsHasNoBuildings">
          <b-spinner v-if="generatingSurrounds" small></b-spinner>
          Generate OSM Surrounds
        </b-button>
        <div
          v-else-if="surroundsStatusOSM === 'REQUESTED'"
          class="text-secondary">
          <b-spinner variant="primary" small></b-spinner>
          Generating OSM Surrounds...
        </div>
        <b-button
          class="ml-4"
          variant="primary"
          v-else-if="surroundsStatusOSM === 'AVAILABLE'"
          @click="downloadSurroundsOSM">
          Download OSM Surrounds File
        </b-button>
        <div class="no-top-margin-all-descendants mb-2 mt-4">
          <h6 class="mb-2">Weather Data</h6>
          <div v-if="this.selectedProject == null" class="d-flex justify-content-center" >
            <b-spinner variant="primary"></b-spinner>
          </div>

          <!-- there is weather data for a project, show selector -->
          <div v-if="this.selectedProject !== null && this.metDataRequested && !showAddNewWeatherData"> 
            <p class="d-flex flex-row justify-items-center ml-4 mt-2 mb-2"> 
              <strong class="mt-1"> Source:</strong> 
              <b-dropdown variant="outline-primary" class="select-weather-dropdown ml-4">
                <template #button-content>
                  {{ metSourceToName(selectedMetSource) }}
                </template>
                <b-dropdown-item 
                  class="overflow-hidden"
                  v-for="(source, index) in metSources" 
                  :key="index"
                  @click="selectMetSource(index)">
                  {{ metSourceToName(source) }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button 
                v-if="!showAddNewWeatherData && this.allow_add_new_weather_sources"
                @click="toggleAddNewWeatherData" 
                class="ml-4"
                variant="primary">
                + New Weather Source
              </b-button>
            </p>
            
            <div v-if="!showAddNewWeatherData" class="border ml-4 p-4">
              <div v-if="getMetDataType==='CUSTOM' && hasCustomMetData && getCustomMetData" class="d-flex flex-column">
                <p> <strong> Type </strong>: Custom </p>
                <p class="mt-0"> 
                  <strong> File Name:</strong> {{ getCustomMetData.name }}
                </p>
                <p class="mt-0"> 
                  <strong> Label:</strong> {{ getCustomMetData.label }}
                </p>
                <p class="mt-0"> 
                  <strong> Format: </strong> {{ getCustomMetData.format }}
                </p>
                <p class="mt-0">
                  <strong> Height: </strong> {{ getCustomMetData.height }}m
                </p>
              </div>
              <div v-if="getMetDataType==='MODELLED'" class="d-flex flex-column">
                <p class="mt-0"> 
                    <strong> Type: </strong> Modelled 
                </p>
                <p v-if="getProjectPosition" class="mt-0"> 
                  <strong>Project Location:</strong> {{ getProjectPosition.lat }} / {{ getProjectPosition.lng }} 
                </p>
                <p v-if="selectedProject.terrain_correction_method">
                  <strong>Terrain Correction Method: </strong>{{ selectedProject.terrain_correction_method }}
                </p>
                <b-alert class="mt-2" :show="downloadInProgess" variant="warning">
                  <b-spinner small />
                  Data download in progress.
                </b-alert>
                <b-alert class="mt-2" show variant=danger v-if="selectedMetSource.error">
                  <p class="mb-2 d-flex justify-content-center"><strong>Please select a new weather source</strong></p>
                  {{selectedMetSource.error.message}}
                </b-alert>
                <project-resource-map-modal
                  v-if="getProjectPosition != null"
                  :stationData="getStationData"
                  :metDataType="getMetDataType"
                  :projectPosition="getProjectPosition"
                />
              </div>
              <div v-if="getMetDataType==='MEASURED'">
                <div v-if="showMetStation && getStationData">
                  <div class="d-flex flex-column">
                    <p class="mt-0">
                      <strong> Type: </strong>Station
                    </p>
                    <p class="mt-0" v-if="getStationData.name"> 
                      <strong> Station Name: </strong> {{ getStationData.name }}</p>
                    <p v-if="selectedProject.terrain_correction_method">
                      <strong>Terrain Correction Method: </strong>{{ selectedProject.terrain_correction_method }}
                    </p>
                    <b-alert class="mt-2" :show="downloadInProgess" variant="warning">
                      <b-spinner small />
                      Data download in progress.
                    </b-alert>
                    <b-alert class="mt-2" show variant=danger v-if="selectedMetSource.error">
                      <p class="mb-2 d-flex justify-content-center"><strong>Please select a new weather source</strong></p>
                      {{selectedMetSource.error.message}}
                    </b-alert>
                  </div>
                  <project-resource-map-modal
                    v-if="getProjectPosition != null"
                    :stationData="getStationData"
                    :metDataType="getMetDataType"
                    :projectPosition="getProjectPosition"
                    :hasCustomMetData="hasCustomMetData" 
                  />
                </div>
              </div>
              <div v-if="getMetDataType==='FUTURE'">
                <p class="mt-0">
                  <strong> Climate Scenario: </strong>{{getFutureMetClimateScenario}}
                </p>
                <p class="mt-0"> 
                  <strong> Time Frame: </strong> {{ getFutureMetTimeframe }}
                </p>
                <b-alert class="mt-2" :show="downloadInProgess" variant="warning">
                  <b-spinner small />
                  Data download in progress.
                </b-alert>
                 <b-alert class="mt-2" show variant=danger v-if="selectedMetSource.error">
                  <p class="mb-2 d-flex justify-content-center"><strong>Please select a new weather source</strong></p>
                  {{selectedMetSource.error.message}}
                </b-alert>
                <b-card class="mt-2">
                  <h5>{{climateScenarioTitle}}</h5>
                  <p class="mt-2">{{climateScenarioDescription}}</p>
                </b-card>
              </div>
            </div>
          </div>
          <!-- there is NO weather data for the project, show "no weather data" label and a button to get some -->
          <div v-if="this.selectedProject !== null && !this.metDataRequested && !showAddNewWeatherData"> 
            
            <p class="ml-4 mt-1 mb-2">This project does not currently have any weather data available</p>
            <b-button 
              v-if="!this.metDataRequested"
              @click="toggleAddNewWeatherData" 
              class="ml-4 px-4"
              variant="primary">
              + Add Weather Source
            </b-button>
            
          </div>

          <!-- form to add new weather data source -->
          <div v-if="showAddNewWeatherData" class="border ml-4 p-4">
            <h6>Add New Weather Data</h6>
            <weather-data-form 
              :latitude="selectedProject.latitude"
              :longitude="selectedProject.longitude"
              :displayStationsFlag="displayStationsFlag"
              :existingStationsForProject="existingStations"
              :includeModelledDataOption="!metSourcesContainsModelled"
              @setDisabled="setAddNewWeatherDataDisabled" 
              @setCustomMetUploadRadioValue="setCustomMetUploadRadioValue"
              @setSelectedStationData="setSelectedStationData"
              @setCustomWeatherDataFile="setCustomWeatherDataFile"
              @setCustomWeatherDataHeight="setCustomWeatherDataHeight"
              @setMetFileFormatRadioValue="setMetFileFormatRadioValue"
              @setTimeframe="setTimeframe"
              @setClimateScenario="setClimateScenario" 
              @setCustomWeatherDataLabel="setCustomWeatherDataLabel"/>
            <div class="mt-4 d-flex justify-content-between">
              <b-button
                @click="cancelAddWeatherSource"
                variant="danger">
              Cancel
              </b-button>
              <b-button 
                :disabled="addNewWeatherDataDisabled || adding_weather_source"
                @click="addNewWeatherData" 
                variant="primary">
                <b-spinner small v-if="adding_weather_source" />
                <span v-else>Add Weather Source</span>
              </b-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import  ProjectResourceMapModal from './ProjectResourceMapModal';
import _ from 'lodash';
import { latLng } from 'leaflet';
import WeatherDataForm from './WeatherDataForm';
import { EventBus } from '@/network/eventbus';
import { 
  SSP1_title,
  SSP2_title,
  SSP3_title,
  SSP4_title,
  SSP5_title,
  SSP1_description,
  SSP2_description,
  SSP3_description,
  SSP4_description,
  SSP5_description    
} from '@/constants/climateScenarios';


export default {
  name: 'ProjectSettingsResources',
  components: {
    ProjectResourceMapModal,
    WeatherDataForm
  },
  data() {
    return {
      surroundsHasNoBuildings: false,
      generatingSurrounds: false,
      projectErrors: null,
      showAddNewWeatherData: false,
      displayStationsFlag: false,
      addNewWeatherDataDisabled: true,
      metSourceIndex: 0,
      newWeatherData: {
        selectedStationData: {},
        customMetUploadRadioValue: 0,
        customWeatherDataFile: null,
        customWeatherDataHeight: 600,
        customWeatherDataLabel: '',
        metFileFormatRadioValue: 'MIS',
        futureMetTimeFrame: '2050',
        futureMetClimateScenario: 'SSP1'
      },
      adding_weather_source: false
    };
  },
  props: {
    canEditProject: {
      required: false
    },
  },
  created() {
    EventBus.$on('MET_FETCH_COMPLETE', this.onFetchMetComplete);
    EventBus.$on('MET_FETCH_ERROR', this.onFetchMetError);
    EventBus.$on('SURROUNDS_AVAILABLE', this.onSurroundsAvailable);
    EventBus.$on('NO_SURROUNDS_AVAILABLE', this.noSurroundsAvailable);
  },
  beforeDestroy() {
    EventBus.$off('MET_FETCH_COMPLETE', this.onFetchMetComplete);
    EventBus.$off('MET_FETCH_ERROR', this.onFetchMetError);
    EventBus.$off('SURROUNDS_AVAILABLE', this.onSurroundsAvailable);
    EventBus.$off('NO_SURROUNDS_AVAILABLE', this.noSurroundsAvailable);
  },
  mounted() {
    if (this.selectedProject) {
      this.getProjectAssetUploadUrl(this.selectedProject.id);
    }
  },
  computed: {
    climateScenarioTitle() {
      if (this.getFutureMetClimateScenario == 'SSP1') {
        return SSP1_title;
      } else if (this.getFutureMetClimateScenario == 'SSP2') {
        return SSP2_title;
      } else if (this.getFutureMetClimateScenario == 'SSP3') {
        return SSP3_title;
      } else if (this.getFutureMetClimateScenario == 'SSP4') {
        return SSP4_title;
      } else if (this.getFutureMetClimateScenario == 'SSP5') {
        return SSP5_title;
      }
      return '';
    },
    climateScenarioDescription() {
      if (this.getFutureMetClimateScenario == 'SSP1') {
        return SSP1_description;
      } else if (this.getFutureMetClimateScenario == 'SSP2') {
        return SSP2_description;
      } else if (this.getFutureMetClimateScenario == 'SSP3') {
        return SSP3_description;
      } else if (this.getFutureMetClimateScenario == 'SSP4') {
        return SSP4_description;
      } else if (this.getFutureMetClimateScenario == 'SSP5') {
        return SSP5_description;
      }
      return [];
    },
    existingStations() {
      //get a list of existing stations so they can be filtered out of the stations list when adding a new met source
      let current_stations = [];
      for (let met_source of this.metSources) {
        if (met_source.wind_data_type === 'MEASURED') {
          current_stations.push(met_source.station);
        }
      }

      return current_stations;
    },
    metSourcesContainsModelled() {
      //if the user already has modelled data for their met source, then don't let them get it again
      for (let met_source of this.metSources) {
        if (met_source.wind_data_type === 'MODELLED') {
          return true;
        }
      }

      return false;
    },
    metSources() {
      return this.selectedProject.met_sources;
    },
    selectedMetSource() {
      if (this.metSources.length == 0) return null;

      return this.metSources[this.metSourceIndex];
      
    },
    downloadInProgess() {
      if (this.selectedMetSource && !this.selectedMetSource.error) {
        if(this.selectedMetSource.wind_data_type === 'MEASURED' || this.selectedMetSource.wind_data_type === 'MODELLED' || this.selectedMetSource.wind_data_type.startsWith('FUTURE')) {
          return this.metDataRequested && this.selectedMetSource.wind_met_data_id == null;            
        }
      }
      return false;
    },
    metData() {
      let met = {
        station: this.newWeatherData.customMetUploadRadioValue==0 ? this.newWeatherData.selectedStationData : {}, 
        futureMet: this.newWeatherData.customMetUploadRadioValue==3 ? { timeframe: this.newWeatherData.futureMetTimeFrame, climateScenario: this.newWeatherData.futureMetClimateScenario } : {},
        custom_met_data: this.newWeatherData.customWeatherDataFile?.files[0].path,
        custom_met_data_file_format: this.newWeatherData.metFileFormatRadioValue,
        custom_met_data_height: this.newWeatherData.customWeatherDataHeight,
        custom_met_data_label: this.newWeatherData.customWeatherDataLabel
      };
      if(this.customWeatherDataLabel?.length > 0 ){
        met['custom_met_data_label'] = this.customWeatherDataLabel;
      }

      return met;
    },
    getCustomMetData(){
      if(!_.isEmpty(this.selectedMetSource?.custom_met_data)){
        let filename = this.customMetSourceFileToFileName(this.selectedMetSource?.custom_met_data?.file);
        return { 
          name: filename, 
          format: this.selectedMetSource?.custom_met_data?.file_format, 
          height: this.selectedMetSource?.custom_met_data?.height, 
          label: this.selectedMetSource?.custom_met_data?.label
        };
      }
      return false;
    },
    getMetDataType(){
      if(this.selectedMetSource) {
        if (this.selectedMetSource.wind_data_type.startsWith('FUTURE')) {
          return 'FUTURE';
        } else {
          return this.selectedMetSource.wind_data_type;
        }
      } else {
        return null;
      }
    },
    getFutureMetClimateScenario() {
      if (this.selectedMetSource.wind_data_type.startsWith('FUTURE')) {
        let tokens = this.selectedMetSource.wind_data_type.split('-');
        return tokens[1];
      }
      
      return null;
    },
    getFutureMetTimeframe() {
      if (this.selectedMetSource.wind_data_type.startsWith('FUTURE')) {
        let tokens = this.selectedMetSource.wind_data_type.split('-');
        return tokens[2];
      }
      
      return null;

    },
    getProjectPosition(){
      if (!this.selectedProject) {
        return null;
      }

      if (this.selectedProject?.latitude && this.selectedProject?.longitude)
        return latLng(this.selectedProject.latitude, this.selectedProject.longitude);    
      return latLng(0, 0);    
    },
    getStationData(){
      return this.selectedMetSource?.station;
    },
    showMetStation(){
      return this.selectedProject?.fetch_met;
    },
    // the user upload custom met data
    hasCustomMetData(){
      return this.selectedProject?.met_sources.some(source => {
        return !(source.custom_met_data === undefined || _.isEmpty(source.custom_met_data));
      });
    },
    metDataRequested(){
      return this.selectedProject?.met_sources.length > 0;
    },
    projectId() {
      return Number(this.$route.params.id);
    },
    projectHasSurroundsOSM() {
      return !!this.surroundsOSM;
    },
    surroundsOSM() {
      return this.projectSurrounds?.find(surrounds => surrounds.source === 'OSM');
    },
    surroundsStatusOSM() {
      return this.surroundsOSM?.status;
    },
    surroundsHasErrorsOSM() {
      if(!this.surroundsOSM?.file && this.surroundsStatusOSM === 'NO_SURROUNDS_AVAILABLE'){
        this.noSurroundsAvailable();
        return false;
      } else if (!this.surroundsOSM?.file){
        return this.surroundsStatusOSM === 'ERROR' || this.surroundsOSM?.errors.length > 0;
      } else return false;
    },
    isProjectInCompany() {
      return this.loggedInUser?.is_superuser || this.project?.company_id == this.loggedInUser?.company_id;
    },
    // projectHasFetchMetError() {
    //   return  this.selectedProject?.errors?.find(error => error.type ==='FetchMet');
    // },
    allow_add_new_weather_sources() {
      if (this.userCompany && this.loggedInUser) {
        if ( this.userCompany?.is_rwdi || this.loggedInUser?.is_superuser) {
          return true;
        } else if (this.hasModuleByName('future met')) {
          return true;
        } else {
          //OSD users can only add another weather station if their primary one has an error
          if (this.selectedMetSource.error != null) {
            return true;
          }
        }
      }
      return false;

    },
    ...mapGetters(['userCompany', 'loggedInUser', 'companySubscriptions']),
    ...mapGetters('project', ['selectedProject', 'projectSurrounds'])
  },
  methods: {
    hasModuleByName(moduleName) {
      let companyHasModule = !!this.userCompany.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
      
      let userSubscriptionHasModule = false;
      if (this.loggedInUser.subscription != null) {
        let user_subscription = this.companySubscriptions.find(x => x.id == this.loggedInUser.subscription);
        if (user_subscription) {
          userSubscriptionHasModule = !!user_subscription.subscription_type.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
        }
      }
      
      return companyHasModule || userSubscriptionHasModule;
    },
    onFetchMetComplete(args) {
      const projectId = parseInt(args.project_id);
      if (this.selectedProject.id.toString() === projectId.toString()) {
        for (let metSource of this.selectedProject.met_sources) {
          if (metSource.id == args.met_source_id) {
            metSource.wind_met_data_id = 1; //the actual Id doesn't matter, just set it to 1 so it's truthy.  The actual Id is not used in the front-end
          }
        }
        
      }
    },
    onFetchMetError(args) {
      const projectId = parseInt(args.project_id);
      if (this.selectedProject.id.toString() === projectId.toString()) {
        for (let metSource of this.selectedProject.met_sources) {
          if (metSource.id == args.met_source_id) {
            metSource.error = args.error;
          }
        }
        
      }
    },
    customMetSourceFileToFileName(file) {
      let filename = String(file).split('?')[0].split('/').pop();
      return decodeURI(filename);
    },
    metSourceToName(metSource) {
      if(metSource.wind_data_type === 'MEASURED') {
        return `Measured data from ${metSource.station.name}`;
      } else if(metSource.wind_data_type === 'MODELLED') {
        return 'Modelled weather data at project site';
      } else if(metSource.wind_data_type === 'CUSTOM' && metSource.custom_met_data) {
        return `Custom file upload: ${this.customMetSourceFileToFileName(metSource.custom_met_data.file)}`;
      } else if (metSource.wind_data_type.startsWith('FUTURE')) {
        let tokens = metSource.wind_data_type.split('-');
        return `Future Climate Scenario ${tokens[1]} for ${tokens[2]} time frame`;
      }
      return '';
    },
    selectMetSource(index) {
      this.metSourceIndex = index;
    },
    resetNewWeatherData() {
      this.newWeatherData = {
        selectedStationData: {},
        customMetUploadRadioValue: 0,
        customWeatherDataFile: null,
        customWeatherDataHeight: 600,
        customWeatherDataLabel: '',
        metFileFormatRadioValue: 'MIS',
        futureMetTimeFrame: '2050',
        futureMetClimateScenario: 'SSP1'
      };
    },
    async addNewWeatherData() {
      this.adding_weather_source = true;
      let resp = await this.$store.dispatch('project/fetchMetData', { 
        projectId: this.selectedProject.id,
        metData: this.metData
      });

      this.resetNewWeatherData();
      this.selectedProject.fetch_met = true;
      this.showAddNewWeatherData = false;
      this.adding_weather_source = false;
      this.selectedProject.met_sources.push(resp[0]);
      
      EventBus.$emit('TOAST', {
        variant: 'success',
        content: 'New weather data added'
      });
    },
    cancelAddWeatherSource() {
      this.showAddNewWeatherData = false;
    },
    setSelectedStationData(stationData) {
      this.newWeatherData.selectedStationData = stationData;
    },
    setCustomWeatherDataFile(file) {
      this.newWeatherData.customWeatherDataFile = file;
    },
    setCustomWeatherDataLabel(label) {
      this.newWeatherData.customWeatherDataLabel = label;
    },
    setCustomWeatherDataHeight(height) {
      this.newWeatherData.customWeatherDataHeight = height;
    },
    setMetFileFormatRadioValue(format) {
      this.newWeatherData.metFileFormatRadioValue = format;
    },
    setCustomMetUploadRadioValue(value) {
      this.newWeatherData.customMetUploadRadioValue = value;
    },
    setAddNewWeatherDataDisabled(value) {
      this.addNewWeatherDataDisabled = value;
    },
    setTimeframe(value) {
      this.newWeatherData.futureMetTimeFrame = value;
    },
    setClimateScenario(value) {
      this.newWeatherData.futureMetClimateScenario = value;
    },   
    async toggleAddNewWeatherData() {
      this.showAddNewWeatherData = !this.showAddNewWeatherData;
      await this.$nextTick();
      this.displayStationsFlag = !this.displayStationsFlag;
    },
    async generateSurroundsOSM() {
      this.generatingSurrounds = true;
      await this.$store.dispatch('project/generateSurroundsOSM', this.projectId);
    },
    downloadSurroundsOSM() {
      let link = document.createElement('a');
      link.download = 'surrounds.stl';
      link.href = this.surroundsOSM.file;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async onSurroundsAvailable(args) {      
      const projectId = parseInt(args.project_id);
      if (this.selectedProject.id.toString() === projectId.toString()){
        await this.$store.dispatch('project/getProjectSurrounds', projectId);
        this.generatingSurrounds = false;
      }      
    },
    async noSurroundsAvailable() {      
      this.surroundsHasNoBuildings = true;
      this.generatingSurrounds = false;         
    },
    ...mapActions({
      getProjectAssetUploadUrl: 'project/getProjectAssetUploadUrl'
    })  
  },
  watch: {
    selectedProject(newValue) {
      if (newValue) {
        this.getProjectAssetUploadUrl(newValue.id);
      }
    }
    
  }
};
</script>

<style scoped>
.panel {
  margin: 0;
  background-color: #fff;
  min-height: 20em;
  display: flex;
}

.main {
  padding: 1rem;
  width: 100%;
}
</style>

<style>
.select-weather-dropdown button:first-child {
  text-align: left;
  font-size: 0.9em;
  color: var(--grey-900) !important;
  background: #FFF !important;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}

.select-weather-dropdown ul {
  z-index: 1001;
  padding: .75em 2em .75em .75em;
}

.select-weather-dropdown a {
  text-decoration: none;
  font-size: 0.9em;
  line-height: 1.2em;
  color: #000;
  padding: 0px 2px 1px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}
</style>